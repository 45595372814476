import AddIcon from "@/core/ui/iconsax/linear/add.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import blankExperienceTemplateCover from "@assets/images/covers/blank-experience-template-cover.png"
import styleIf from "@assets/style/util/styleIf"
import { DiscoButton, DiscoIcon, DiscoText, DiscoTooltip } from "@disco-ui"
import classNames from "classnames"

interface Props {
  testid: string
  title: string
  description: string
  cover?: string
  onClick(): void
  isSelected?: boolean
  tooltip?: string
  isRecommended?: boolean
  disabled?: boolean
  svg?: React.ReactNode
}

function DiscoTemplateCardButton(props: Props) {
  const {
    title,
    description,
    testid,
    onClick,
    tooltip,
    isSelected = false,
    isRecommended = false,
    disabled = false,
    svg,
  } = props

  let { cover } = props
  if (!cover && !svg) {
    cover = blankExperienceTemplateCover
  }

  const classes = useStyles({ disabled })

  return (
    <DiscoTooltip disabled={!tooltip} content={tooltip}>
      <span>
        <DiscoButton
          color={"transparent"}
          testid={testid}
          onClick={onClick}
          className={classNames(classes.root, { [classes.selectedRoot]: isSelected })}
          disabled={disabled}
          style={disabled ? { pointerEvents: "none" } : {}}
        >
          <div
            className={"cover"}
            style={
              cover
                ? { backgroundImage: `url(${cover})` }
                : { paddingTop: 0, background: "unset" }
            }
          >
            {svg}
            {isSelected ? (
              <DiscoIcon
                width={24}
                height={24}
                className={"cover-icon"}
                icon={"check-circle"}
              />
            ) : (
              <AddIcon className={"cover-icon"} />
            )}
          </div>
          <DiscoText variant={"body-md-600"} marginTop={2}>
            {title}
          </DiscoText>
          <DiscoText
            variant={"body-xs-500"}
            className={classes.description}
            component={"p"}
          >
            {description}
          </DiscoText>
          {isRecommended && (
            <div className={classes.recommendedTag}>
              <DiscoIcon className={classes.recommendedTagIcon} icon={"check-circle"} />
              {"Recommended"}
            </div>
          )}
        </DiscoButton>
      </span>
    </DiscoTooltip>
  )
}

type StyleProps = {
  disabled: boolean
}

const useStyles = makeUseStyles((theme) => ({
  root: ({ disabled }: StyleProps) => ({
    backgroundColor: "transparent !important",
    height: "100%",
    position: "relative",
    padding: theme.spacing(1.25),
    border: `1px solid ${theme.palette.constants.stroke}`,
    borderRadius: theme.measure.borderRadius.big,
    boxShadow: theme.palette.groovyDepths.insideCard,
    display: "block",
    textAlign: "left",
    maxWidth: "200px",
    "& .cover": {
      position: "relative",
      width: "100%",
      paddingTop: "50%",
      borderRadius: theme.measure.borderRadius.medium,
      backgroundSize: "cover",
    },
    "& .cover-icon": {
      display: "none",
    },

    ...styleIf(disabled, {
      opacity: 0.35,
    }),

    ...styleIf(!disabled, {
      "&:hover": {
        borderColor: theme.palette.primary.main,
        "& .cover::after": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: 179,
          height: 89,
          background: theme.palette.primary.main,
          borderRadius: theme.measure.borderRadius.medium,
        },
        "& .cover-icon": {
          display: "block",
          position: "absolute",
          top: "50%",
          left: "50%",
          height: 24,
          width: 24,
          transform: "translateX(-50%) translateY(-50%)",
          color: theme.palette.primary.contrastText,
          zIndex: theme.zIndex.raise1,
        },
      },
    }),
  }),
  selectedRoot: {
    border: `2px solid ${theme.palette.primary.main} !important`,
    "& .cover::after": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: theme.palette.primary.main,
      borderRadius: theme.measure.borderRadius.medium,
    },
    "& .cover-icon": {
      display: "block !important",
      position: "absolute",
      top: "50%",
      left: "50%",
      height: 24,
      width: 24,
      transform: "translateX(-50%) translateY(-50%)",
      fill: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
      zIndex: theme.zIndex.raise1,
    },
    "&:hover": {
      "& .cover-icon": {
        fill: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
  },
  description: {
    // Override specificity of the button
    color: `${theme.palette.text.secondary} !important`,
  },
  recommendedTag: {
    display: "flex",
    position: "absolute",
    fontWeight: 600,
    borderRadius: theme.measure.borderRadius.default,
    alignItems: "center",
    padding: theme.spacing(0.5, 1),
    gap: "3px",
    height: "22px",
    right: theme.spacing(1),
    top: theme.spacing(1.25),
    fontSize: "11px",
    boxShadow: theme.palette.groovyDepths.insideCard,
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
  },

  recommendedTagIcon: {
    color: theme.palette.primary.main,
    fill: "white",
    width: "15px",
  },
}))

export default DiscoTemplateCardButton
