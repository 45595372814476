import useTrackAssetVideoProgress from "@/content/util/useTrackAssetVideoProgress"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import DiscoVideo, { DiscoVideoProps } from "@disco-ui/video/DiscoVideo"
import { DiscoAssetVideoQuery } from "@disco-ui/video/__generated__/DiscoAssetVideoQuery.graphql"
import { TestIDProps } from "@utils/typeUtils"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

type DiscoAssetVideoProps = TestIDProps &
  Omit<DiscoVideoProps, "assetKey" | "onVideoTimeUpdate"> & {
    assetId: GlobalID
    assetUsageId?: GlobalID
    contentId?: GlobalID
    contentUsageId?: GlobalID
    trackWatchProgress: boolean
  }

function DiscoAssetVideo({
  assetId,
  assetUsageId,
  contentId,
  contentUsageId,
  trackWatchProgress,
  ...rest
}: DiscoAssetVideoProps) {
  const trackAssetVideoProgress = useTrackAssetVideoProgress()

  const { asset } = useLazyLoadQuery<DiscoAssetVideoQuery>(
    graphql`
      query DiscoAssetVideoQuery($id: ID!) {
        asset: node(id: $id) {
          ... on Asset {
            id
            ...DiscoVideoFragment
          }
        }
      }
    `,
    {
      id: assetId || "",
    }
  )

  return (
    <DiscoVideo
      {...rest}
      assetKey={asset}
      onVideoTimeUpdate={(seconds: number, percent: number) => {
        if (!trackWatchProgress) return
        if (!assetUsageId || !contentId) return
        trackAssetVideoProgress({
          input: {
            assetUsageId,
            contentId,
            contentUsageId,
            seconds,
            percent,
          },
        })
      }}
    />
  )
}

const DiscoAssetVideoSkeleton = () => {
  return null
}

export default Relay.withSkeleton({
  component: DiscoAssetVideo,
  skeleton: DiscoAssetVideoSkeleton,
})
