export const DiscoIconToIconsaxMap = {
  money: "money",
  database: "coin",
  wallet: "wallet",
  tag: "tag",
  ticket: "ticket-star",
  "credit-card": "card",
  "align-left": "textalign-left",
  "align-center": "textalign-center",
  "align-right": "textalign-right",
  "align-justify": "textalign-justifycenter",
  paperclip: "custom-paperclip",
  link: "link",
  italic: "text-italic",
  text: "text",
  bold: "text-bold",
  underline: "text-underline",
  moon: "moon",
  sun: "sun-1",
  folder: "folder",
  "folder-dock": "folder-2",
  mic: "microphone-2",
  "sound-max": "volume-high",
  "video-square": "video-square",
  image: "gallery",
  camera: "camera",
  play: "play",
  book: "book-1",
  box: "box",
  music: "music",
  video: "play-circle",
  event: "record-circle",
  stop: "pause",
  "switch-horizontal": "arrow-2",
  "arrow-stem-right": "arrow-right",
  "arrow-stem-left": "arrow-left",
  "arrow-stem-up": "arrow-up",
  "arrow-stem-down": "arrow-down-1",
  "arrow-right": "arrow-right-1",
  "arrow-left": "arrow-left-1",
  "arrow-up": "arrow-up-1",
  "arrow-down": "arrow-down",
  refresh: "rotate-right",
  "refresh-cw-02": "refresh-2",
  export: "export",
  "sign-in": "login",
  "sign-up": "logout",
  logout: "logout",
  "external-link": "export-2",
  repeat: "repeat",
  user: "user",
  "user-square": "user-square",
  users: "profile-2user",
  "user-circle": "profile-circle",
  people: "people",
  inbox: "direct-normal",
  "archive-load": "direct-inbox",
  discussion: "messages-3",
  email: "sms",
  message: "message-text",
  location: "location",
  globe: "global",
  gps: "gps",
  "graduation-cap": "teacher",
  gift: "gift",
  "3d-box": "box-1",
  eye: "eye",
  "eye-off": "eye-slash",
  shield: "shield",
  lock: "lock-1",
  "lock-unlocked": "unlock",
  brush: "brush",
  "magic-wand": "magicpen",
  "life-buoy": "lifebuoy",
  pen: "path-2",
  layer: "layer",
  copy: "copy",
  table: "grid-8",
  grid: "element-3",
  "widget-add": "element-plus",
  bag: "bag-2",
  store: "shop",
  file: "document-text",
  "file-check": "clipboard-tick",
  "file-dock": "clipboard-text",
  edit: "edit",
  pencil: "edit-2",
  trash: "trash",
  house: "home",
  "check-circle": "tick-circle",
  add: "add",
  "add-circle": "add-circle",
  delete: "close-circle",
  warning: "danger",
  send: "send-2",
  "group-share": "share",
  lightning: "flash",
  "lightning-ring": "flash-circle",
  flag: "flag",
  archive: "archive",
  "filter-funnel": "filter",
  diamond: "diamonds",
  star: "star-1",
  favourite: "heart",
  achievement: "medal-star",
  bell: "notification",
  lightbulb: "lamp-on",
  settings: "setting-2",
  bookmark: "custom-archive",
  time: "clock-1",
  timer: "timer-1",
  calendar: "calendar",
  "calendar-check": "calendar-tick",
  "calendar-check-alt": "calendar-tick",
  headphone: "headphone",
  phone: "call",
  "chart-bar": "chart-2",
  "chart-pie": "graph",
  "chart-line": "activity",
  search: "search-normal-1",
  "chat-alt": "message-text",
  map: "map-1",
  "page-writing": "document-text",
  page: "document-1",
  comment: "message",
  "color-swatch": "color-swatch",
  date: "calendar",
  blank: "document-1",
  pound: "hashtag-1",
  messages: "messages-2",
  iframe: "code",
  flash: "flash",
  pause: "pause",
  story: "story",
  "scan-barcode": "scan-barcode",
  "bar-chart": "chart",
  "log-in": "login",
  sort: "arrow-3",

  /**
   * 'Different names on storybook' ie: exporting same icon with different names
   */
  "user-remove": "custom-user-minus",
  attachment: "custom-paperclip",
  chevron: "arrow-up-1",
  "chevron-down": "arrow-down",
  view: "eye",
  "view-hide": "eye-slash",
  "add-round": "add",
  "send-01": "send-2",
  setting: "setting-2",
  "date-range": "calendar",
  "layers-three": "layer",
  "book-closed": "book",
  "home-alt": "home",
  label: "tag",
  paper: "receipt-item",
  "book-open": "book-1",
  "file-alt": "document-text",
  "filter-bars": "sort",

  /**
   * Custom icons from Figma
   */
  expand: "custom-expand",
  check: "custom-check",
  done: "custom-check",
  close: "custom-x",
  alarm: "custom-alarm",
  download: "custom-download",
  upload: "custom-upload",
  callout: "custom-distribute-spacing-vertical",
  "cursor-click": "custom-cursor-click",
  list: "custom-dotpoints",
  feather: "custom-feather",
  palette: "custom-palette",
  reorder: "custom-dots-tune",
  "book-check": "custom-book-check",
  question: "custom-question",
  "bell-pin": "custom-bell-pin",
  pin: "custom-pin",
  save: "custom-save",
  nesting: "custom-nesting",
  critical: "custom-critical",
  "widget-alt": "custom-widget-alt",
  "public-page": "custom-public-page",
  info: "custom-info",
  "file-search": "custom-file-search",
  "color-mode": "custom-color-mode",
  "done-all": "custom-done-all",
  share: "custom-share",
  rocket: "custom-rocket",
  "puzzle-piece": "custom-puzzle-piece",
  "at-sign": "custom-at-sign",
  sparkles: "custom-sparkles",
  stars: "custom-stars",
  "video-check": "custom-video-check",
  code: "custom-code",
  substacks: "custom-subtasks",
  reduce: "custom-collapse",
  "transparent-icon": "custom-transparent",
  "repeat-credit-card": "custom-credit-card-refresh",
  "video-file": "custom-video-file",
  import: "custom-download",
  "user-add": "custom-user-add",
  compass: "custom-compass",

  /**
   * apps
   */
  "icon-discord": "logo-discord",
  "icon-airtable": "logo-airtable",
  "icon-google-doc": "logo-google-doc",
  "icon-google-form": "logo-google-form",
  "icon-typeform": "logo-typeform",
  "icon-whereby": "logo-whereby",
  "icon-figma": "logo-figma",
  "icon-miro": "logo-miro",
  "icon-slack": "logo-slack",
  "icon-scorm": "logo-scorm",

  /**
   * editor
   */
  divider: "custom-divider",
  h1: "custom-h1",
  h2: "custom-h2",
  h3: "custom-h3",
  strikethrough: "custom-strikethrough",
  "numbered-list": "custom-numbered-list",
  quote: "custom-quote",
} as const

// map Iconsax Linear variant names to Bold variant names
// null indicates we have no assigned Bold variant, and should fallback to linear
export const IconsaxLinearToBoldMap = {
  activity: "activity",
  add: null,
  "add-circle": "add-circle",
  archive: "archive",
  "arrow-2": null,
  "arrow-3": null,
  "arrow-down": null,
  "arrow-down-1": null,
  "arrow-left": null,
  "arrow-left-1": null,
  "arrow-right": null,
  "arrow-right-1": null,
  "arrow-up": null,
  "arrow-up-1": null,
  "bag-2": "bag-2",
  book: "book",
  "book-1": "book-1",
  bookmark: "bookmark",
  box: "box",
  "box-1": "box-1",
  brush: "brush-2",
  calendar: "calendar",
  "calendar-tick": "calendar-tick",
  call: "call",
  camera: "camera",
  card: "card",
  chart: "chart-1",
  "chart-2": "chart-2",
  "clipboard-text": "clipboard-text",
  "clipboard-tick": "clipboard-tick",
  "clock-1": "clock",
  "close-circle": "close-circle",
  code: "code",
  coin: "coin",
  "color-swatch": "color-swatch",
  copy: "copy",
  cup: "cup",
  "custom-alarm": "custom-alarm",
  "bold/arrow-down": null,
  "custom-archive": "custom-archive",
  "custom-at-sign": null,
  "custom-bell-pin": "custom-bell-pin",
  "custom-book-check": null,
  "custom-check": "custom-check",
  "custom-code": null,
  "custom-credit-card-refresh": null,
  "custom-critical": "custom-critical",
  "custom-color-mode": null,
  "custom-compass": null,
  "custom-cursor-click": "custom-cursor-click",
  "custom-done-all": null,
  "custom-dots-tune": null,
  "custom-dotpoints": null,
  "custom-expand": null,
  "custom-info": null,
  "custom-nesting": null,
  "custom-collapse": "custom-collapse",
  "custom-transparent": null,
  "custom-upload": "custom-upload",
  "custom-stars": "custom-stars",
  "custom-subtasks": "custom-subtasks",
  "custom-distribute-spacing-vertical": "custom-distribute-spacing-vertical",
  "custom-download": "custom-download",
  "custom-feather": "custom-feather",
  "custom-file-search": "custom-file-search",
  "custom-palette": "custom-palette",
  "custom-paperclip": null,
  "custom-public-page": null,
  "custom-puzzle-piece": "custom-puzzle-piece",
  "custom-pin": null,
  "custom-question": null,
  "custom-rocket": "custom-rocket",
  "custom-save": "custom-save",
  "custom-share": null,
  "custom-sparkles": "custom-sparkles",
  "custom-widget-alt": "custom-widget-alt",
  "custom-video-check": null,
  "custom-video-file": "custom-video-file",
  "custom-x": null,
  danger: "danger",
  diamonds: "diamonds",
  "direct-inbox": "direct-inbox",
  "direct-normal": "direct-normal",
  "document-1": "document-1",
  "document-text": "document-text",
  edit: "edit",
  "edit-2": "edit-2",
  "element-3": "element-3",
  "element-plus": "element-plus",
  export: "export-3",
  "export-2": "export",
  eye: "eye",
  "eye-slash": "eye-slash",
  filter: "filter",
  flag: "flag",
  flash: "flash",
  "flash-circle": "flash-circle",
  folder: "folder",
  "folder-2": "folder-2",
  gallery: "gallery",
  gift: "gift",
  global: "global",
  gps: "gps",
  graph: "graph",
  "grid-8": "grid-8",
  "hashtag-1": null,
  headphone: "headphone",
  heart: "heart",
  home: "home-1",
  "lamp-on": "lamp-on",
  layer: "layer",
  lifebuoy: "lifebuoy",
  link: null,
  location: "location",
  "lock-1": "lock",
  login: "login-1",
  logout: "logout",
  magicpen: "magicpen",
  "map-1": "map-1",
  "medal-star": "medal-star",
  menu: null,
  message: "message",
  "message-text": "message-text",
  "messages-2": "messages-2",
  "messages-3": "messages-3",
  "microphone-2": "microphone-2",
  minus: null,
  money: "money",
  moon: "moon",
  "bold/custom-more": null,
  music: "music",
  notification: "notification",
  "path-2": "path-2",
  pause: "pause",
  play: "play",
  "play-circle": "play-circle",
  "profile-2user": "profile-2user",
  "profile-circle": "profile-circle",
  people: "people",
  "receipt-item": "receipt-item",
  "record-circle": "record-circle",
  "refresh-2": null,
  repeat: null,
  "rotate-right": null,
  "scan-barcode": "scan-barcode",
  "search-normal-1": "search-normal-1",
  "send-2": "send-2",
  "setting-2": "setting-2",
  share: "share",
  shield: "shield",
  shop: "shop",
  sms: "sms",
  sort: null,
  "star-1": "star-1",
  story: "story",
  "sun-1": "sun-1",
  tag: "tag",
  teacher: "teacher",
  text: null,
  "text-bold": null,
  "text-italic": null,
  "text-underline": null,
  "textalign-center": null,
  "textalign-justifycenter": null,
  "textalign-left": null,
  "textalign-right": null,
  "tick-circle": "tick-circle",
  "ticket-star": "ticket-star",
  "timer-1": null,
  trash: "trash",
  unlock: "unlock",
  user: "user",
  "custom-user-add": null,
  "custom-user-minus": null,
  "user-square": "user-square",
  "video-square": "video-square",
  "volume-high": "volume-high",
  wallet: "wallet",

  /**
   * app icons
   */
  "logo-discord": null,
  "logo-airtable": null,
  "logo-google-doc": null,
  "logo-google-form": null,
  "logo-typeform": null,
  "logo-whereby": null,
  "logo-figma": null,
  "logo-miro": null,
  "logo-slack": null,
  "logo-scorm": null,

  /**
   * editor
   */
  "custom-divider": null,
  "custom-h1": null,
  "custom-h2": null,
  "custom-h3": null,
  "custom-strikethrough": null,
  "custom-numbered-list": null,
  "custom-quote": null,
} as const

// key = iconsax linear name, value = comments
export const NEEDS_REVIEW = {
  "custom-pin": "Can't export bold from figma, only linear",
  "custom-nesting": "Can't export bold from figma, only linear",
}

export type IconsaxIconKinds = `iconsax.${keyof typeof IconsaxLinearToBoldMap}`
