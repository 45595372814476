import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoIcon, { DiscoIconKinds } from "@disco-ui/icon/DiscoIcon"
import DiscoText from "@disco-ui/text/DiscoText"
import DiscoTooltip from "@disco-ui/tooltip/DiscoTooltip"
import { darken, lighten, Radio, RadioProps } from "@material-ui/core"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"

interface DiscoRadioBoxProps extends TestIDProps {
  value: string
  icon?: DiscoIconKinds | React.ReactNode
  iconActive?: boolean
  showIconBackground?: boolean
  title?: React.ReactNode
  subtitle?: React.ReactNode
  content?: React.ReactNode
  RadioProps?: Omit<RadioProps, "checkedIcon" | "icon">
  variant?: "default" | "compact"
  classes?: Partial<ClassNameMap<"radioRoot">>
  tooltip?: React.ReactNode
  tooltipInteractive?: boolean
  disabled?: boolean
  showRadio?: boolean
}

function DiscoRadioBox({
  value,
  icon,
  title,
  subtitle,
  content,
  testid = "DiscoRadioBox",
  variant = "default",
  disabled = false,
  tooltip,
  tooltipInteractive,
  RadioProps: CustomRadioProps,
  classes: customClasses,
  ...rest
}: DiscoRadioBoxProps) {
  const classes = useStyles({})
  return (
    <DiscoTooltip content={tooltip} disabled={!disabled} interactive={tooltipInteractive}>
      <div className={classes.container}>
        <Radio
          value={value}
          disabled={disabled}
          checkedIcon={
            <DiscoRadioBoxIcon
              {...rest}
              variant={variant}
              checked
              icon={icon}
              title={title}
              subtitle={subtitle}
              content={content}
            />
          }
          icon={
            <DiscoRadioBoxIcon
              {...rest}
              variant={variant}
              icon={icon}
              title={title}
              subtitle={subtitle}
              content={content}
            />
          }
          // Can only add a title if it's a string
          inputProps={{ title: typeof title === "string" ? title : undefined }}
          disableRipple
          color={"default"}
          classes={{
            root: classNames(classes.radioRoot, customClasses?.radioRoot, {
              [classes.disabled]: disabled,
            }),
            checked: classNames(classes.radioChecked, {
              [classes.disabled]: disabled,
            }),
          }}
          data-testid={testid}
          {...CustomRadioProps}
        />
      </div>
    </DiscoTooltip>
  )
}

type StyleProps = {
  checked?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    [theme.breakpoints.down("xs")]: {
      flex: "1 1 auto",
    },
  },
  radioRoot: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    borderRadius: theme.measure.borderRadius.big,
    color: theme.palette.text.primary,
    border: `2px solid ${theme.palette.groovy.grey[200]}`,
    textAlign: "unset",
    padding: theme.spacing(2),
    height: "100%",
    "&:hover": {
      backgroundColor: "unset",
      boxShadow: theme.palette.groovyDepths.boxShadow,
    },
    [theme.breakpoints.down("xs")]: {
      flex: "1 1 auto",
    },
    "& > *": {
      height: "100%",
    },
  },
  radioChecked: () => {
    const backgroundColor =
      theme.palette.type === "light"
        ? lighten(theme.palette.primary.light, 0.5)
        : darken(theme.palette.primary.dark, 0.8)
    return {
      color: theme.palette.primary.main,
      backgroundColor,
      borderColor: theme.palette.primary.main,
      "&:hover": {
        // don't change color on hover
        backgroundColor,
      },
      "& $iconContainer": {
        backgroundColor:
          theme.palette.type === "light"
            ? theme.palette.primary.light
            : theme.palette.primary.dark,
      },
    }
  },
  compactIconTitle: {
    display: "flex",
    gap: theme.spacing(1.5),
    alignItems: "center",
    flexGrow: 1,
  },
  iconContainer: {
    borderRadius: theme.measure.borderRadius.medium,
    backgroundColor: theme.palette.groovy.neutral[200],
    padding: theme.spacing(1),
    display: "inline-flex",
  },
  content: {
    marginTop: theme.spacing(1),
  },
  disabled: {
    opacity: 0.35,
    cursor: "not-allowed",
  },
  boxIconRoot: {
    width: "100%",
    height: "100%",
  },
  top: {
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
  radioIcon: {
    borderRadius: "50%",
    width: 24,
    height: 24,
    border: `2px solid ${theme.palette.constants.stroke}`,
    transition: "background-color 0.2s ease-in-out",
    flexShrink: 0,
  },
  radioCheckedIcon: {
    backgroundColor: theme.palette.background.paper,
    border: `7px solid ${theme.palette.primary.main}`,
  },
  radioBoxText: ({ checked }: StyleProps) => ({
    "&, & p, & span": {
      color: `${
        checked ? theme.palette.primary.main : theme.palette.text.secondary
      } !important`,
    },
  }),
}))

function DiscoRadioBoxIcon({
  checked = false,
  variant,
  icon,
  iconActive,
  showIconBackground,
  title,
  subtitle,
  content,
  showRadio = false,
}: Pick<
  DiscoRadioBoxProps,
  | "icon"
  | "iconActive"
  | "showIconBackground"
  | "title"
  | "subtitle"
  | "content"
  | "variant"
  | "showRadio"
> &
  Pick<RadioProps, "checked">) {
  const classes = useStyles({
    checked,
  })

  return (
    <span className={classes.boxIconRoot}>
      <div className={classes.top}>
        <span className={variant === "compact" ? classes.compactIconTitle : ""}>
          {icon &&
            (typeof icon === "string" ? (
              <div className={showIconBackground ? classes.iconContainer : undefined}>
                <DiscoIcon
                  icon={icon as DiscoIconKinds}
                  active={iconActive}
                  color={"currentColor"}
                />
              </div>
            ) : (
              icon
            ))}
          <div>
            <DiscoText variant={"body-md-600"} className={classes.radioBoxText}>
              {title}
            </DiscoText>
            {subtitle && (
              <DiscoText variant={"body-sm"} className={classes.radioBoxText}>
                {subtitle}
              </DiscoText>
            )}
          </div>
        </span>
        {showRadio && (
          <div
            className={classNames(classes.radioIcon, {
              [classes.radioCheckedIcon]: checked,
            })}
          />
        )}
      </div>

      {content && <div className={classes.content}>{content}</div>}
    </span>
  )
}

export default DiscoRadioBox
