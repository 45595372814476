import { DiscoMultiSelectOption } from "@disco-ui/select/DiscoMultiSelect"
import { SelectOption } from "@disco-ui/select/DiscoSelect"
import { FilterOptionsState } from "@material-ui/lab"
import { useCallback } from "react"

export interface FilterSelectOptionsConfig {
  maxVisible?: number | null
  suggestionMatcher?: RegExp
  disableFiltering?: boolean
}

/** Filter select select options by search terms */
export function useFilterSelectOptions<T = any>(
  config?: FilterSelectOptionsConfig
): (
  options: SelectOption<T>[],
  state: FilterOptionsState<SelectOption<T>>
) => SelectOption<T>[]
export function useFilterSelectOptions(
  config?: FilterSelectOptionsConfig
): (
  options: DiscoMultiSelectOption[],
  state: FilterOptionsState<DiscoMultiSelectOption>
) => DiscoMultiSelectOption[]
export function useFilterSelectOptions<T = any>(config: FilterSelectOptionsConfig = {}) {
  const {
    // Default to showing all options to avoid bug reports about missing options
    maxVisible = null,
    suggestionMatcher,
    disableFiltering = false,
  } = config
  return useCallback(
    (
      options: (SelectOption<T> | DiscoMultiSelectOption)[],
      state: FilterOptionsState<SelectOption<T> | DiscoMultiSelectOption>
    ) => {
      let filteredOptions = options
      const searchInput = state.inputValue.toLowerCase()
      if (searchInput && !disableFiltering) {
        filteredOptions = filteredOptions.filter((option) => {
          if (!option.searchable) return option.title.toLowerCase().includes(searchInput)
          return option.searchable.some((searchable) => {
            if (!searchable) return false
            return searchable.toLowerCase().includes(searchInput)
          })
        })
      }

      if (
        filteredOptions.length === 0 &&
        suggestionMatcher &&
        searchInput.match(suggestionMatcher)
      ) {
        // If filtered option is empty and the search input matches the suggestion regex
        // create a suggestion option
        return [
          {
            value: searchInput,
            title: searchInput,
          },
        ]
      }

      if (maxVisible) {
        filteredOptions = filteredOptions.slice(0, maxVisible)
      }

      return filteredOptions
    },
    [maxVisible, suggestionMatcher, disableFiltering]
  )
}
