import makeUseStyles from "@assets/style/util/makeUseStyles"
import useTruncateStyles from "@assets/style/util/useTruncateStyles"
import { DiscoIcon, DiscoIconKinds, DiscoText, DiscoTextSkeleton } from "@disco-ui"
import { Toolbar } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"

interface DiscoToolbarProps extends TestIDProps {
  leftIcon?: React.ReactNode | DiscoIconKinds
  rightOptions?: React.ReactNode
  leftOptions?: React.ReactNode
  title?: string | React.ReactNode
  className?: string
}

function DiscoToolbar({
  title,
  rightOptions,
  leftOptions,
  leftIcon,
  className,
  testid,
}: DiscoToolbarProps) {
  const classes = useStyles({
    hasLeftIcon: Boolean(leftIcon),
  })
  const { truncateMultipleLines } = useTruncateStyles({ numberOfLines: 2 })

  return (
    <Toolbar className={classNames(classes.header, className)}>
      {renderLeftIcon()}
      {typeof title === "string" ? (
        <DiscoText
          variant={"body-lg-600"}
          data-testid={`${testid}.title`}
          className={truncateMultipleLines}
        >
          {title}
        </DiscoText>
      ) : (
        title
      )}
      {leftOptions && (
        <div className={classes.options} data-no-dnd>
          {leftOptions}
        </div>
      )}
      {rightOptions && (
        <div className={classes.options} data-no-dnd>
          {rightOptions}
        </div>
      )}
    </Toolbar>
  )

  function renderLeftIcon() {
    return typeof leftIcon === "string" ? (
      <DiscoIcon icon={leftIcon as DiscoIconKinds} />
    ) : (
      leftIcon
    )
  }
}

interface StyleProps {
  hasLeftIcon: boolean
}

const useStyles = makeUseStyles((theme) => ({
  header: ({ hasLeftIcon }: StyleProps) => ({
    background: theme.palette.background.paper,
    padding: theme.spacing(1.5, 2),
    minHeight: theme.mixins.toolbar.minHeight,
    borderRadius: theme.measure.borderRadius.big,
    display: "grid",
    gridTemplateColumns: `${hasLeftIcon ? "max-content" : ""} 1fr max-content`,
    gap: theme.spacing(1),
    alignItems: "center",
  }),
  options: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
}))

type SkeletonProps = Omit<DiscoToolbarProps, "icon" | "title"> & {
  showTitle?: boolean
  showIcon?: boolean
}

export const DiscoToolbarSkeleton: React.FC<SkeletonProps> = ({
  className,
  leftOptions,
  rightOptions,
  showTitle = true,
  showIcon = true,
  leftIcon,
}: SkeletonProps) => {
  const classes = useStyles({
    hasLeftIcon: Boolean(leftIcon),
  })

  return (
    <div className={classNames(className, classes.header)}>
      {showIcon && <Skeleton variant={"circle"} width={24} height={24} />}
      {showTitle && <DiscoTextSkeleton width={"50%"} />}
      {leftOptions && <div className={classes.options}>{leftOptions}</div>}
      {rightOptions && <div className={classes.options}>{rightOptions}</div>}
    </div>
  )
}

export default DiscoToolbar
