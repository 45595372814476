import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoText from "@disco-ui/text/DiscoText"

export type DiscoProgressBarStepperProps = {
  /** a tuple where the first number represents the current step,
   * and the second number represents total number of steps
   */
  steps: [currentStep: number, totalSteps: number]
  testid?: string
}

export default function DiscoProgressBarStepper(props: DiscoProgressBarStepperProps) {
  const {
    steps: [step, totalSteps],
    testid = "DiscoProgressBarStepper",
  } = props
  const classes = useStyles({ step, totalSteps })

  return (
    <div className={classes.container}>
      <div className={classes.progressBar}>
        <div className={classes.steps} />
      </div>
      <DiscoText
        style={{ whiteSpace: "nowrap" }}
        variant={"body-sm-500"}
        color={"groovy.neutral.500"}
        testid={`${testid}.step-count`}
      >{`Step ${step} of ${totalSteps}`}</DiscoText>
    </div>
  )
}

type StyleProps = {
  step: number
  totalSteps: number
}

const useStyles = makeUseStyles((theme) => ({
  progressBar: {
    width: "100%",
    height: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.groovy.neutral[100],
  },
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
    justifyContent: "space-between",
  },
  steps: ({ step, totalSteps }: StyleProps) => ({
    width: `${(100 / totalSteps) * step}%`,
    height: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.groovy.green[500],
  }),
}))
