import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import {
  ColorPaletteStyles,
  PaletteTextType,
  Spacing,
  TextVariantWithModifiers,
} from "@assets/style/appMuiTheme"
import makeSpacingStyles from "@assets/style/util/makeSpacingStyles"
import makeTypographyStyles from "@assets/style/util/makeTypographyStyles"
import mergeClasses from "@assets/style/util/mergeClasses"
import styleIf from "@assets/style/util/styleIf"
import useTruncateStyles from "@assets/style/util/useTruncateStyles"
import { Typography, TypographyProps } from "@material-ui/core"
import { Palette } from "@material-ui/core/styles/createPalette"
import { Variant as MuiTypographyVariant } from "@material-ui/core/styles/createTypography"
import Skeleton, { SkeletonProps } from "@material-ui/lab/Skeleton"
import classNames from "classnames"
import get from "lodash/get"
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react"

type NullableTextElement = HTMLSpanElement | HTMLParagraphElement | null

export type DiscoTextProps = Partial<StyleProps> &
  Omit<TypographyProps, "variant" | "color"> & {
    testid?: string
    /** The number of lines to truncate text to */
    truncateText?: number
    component?: React.ElementType
    /** Default is 'body-md' */
    variant?: TextVariantWithModifiers
    fontStyle?: "normal" | "italic"
    textDecoration?: string
    lineHeight?: number
  } & Spacing

const DiscoText = forwardRef<HTMLSpanElement, DiscoTextProps>(
  (
    {
      variant = "body-md",
      fontStyle = "normal",
      lineHeight,
      color,
      truncateText,
      margin = 0,
      marginTop,
      marginBottom,
      marginLeft,
      marginRight,
      padding = 0,
      paddingTop,
      paddingBottom,
      paddingLeft,
      paddingRight,
      testid,
      setColor,
      classes: customClasses,
      children,
      textDecoration,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles({
      fontStyle,
      color,
      setColor,
      lineHeight,
      textDecoration,
    })

    // Pack spacing props into a single object
    const spacing = {
      margin,
      marginTop,
      marginBottom,
      marginLeft,
      marginRight,
      padding,
      paddingTop,
      paddingBottom,
      paddingLeft,
      paddingRight,
    }

    const typographyStyles = useTypographyStyles()
    const spacingStyles = useSpacingStyles(spacing)
    const truncateClasses = useTruncateStyles({
      width: "100%",
      numberOfLines: truncateText,
    })
    const [isTextTruncated, setIsTextTruncated] = useState(false)
    const textRef = useRef<NullableTextElement>(null)

    // Assign the forwarded ref to the textRef (if provided)
    useImperativeHandle<NullableTextElement, NullableTextElement>(
      ref,
      () => textRef.current
    )

    useEffect(() => {
      if (!textRef.current) return
      // Text is truncated if the width of the text is less than the scroll width
      setIsTextTruncated(textRef.current.clientWidth < textRef.current.scrollWidth)
    }, [])

    return (
      <Typography
        ref={textRef}
        classes={mergeClasses(classes, customClasses, spacingStyles, {
          root: classNames(
            // Variant specific styles
            typographyStyles[variant],
            {
              [truncateClasses.truncateMultipleLines]: truncateText && truncateText > 1,
              [truncateClasses.truncateLine]: truncateText && truncateText === 1,
            }
          ),
        })}
        variant={VARIANT_TO_TYPOGRAPHY[variant]}
        title={typeof children === "string" && isTextTruncated ? children : undefined}
        data-testid={testid}
        {...rest}
      >
        {children}
      </Typography>
    )
  }
)

export default DiscoText

/**
 * Map each of our variants to the MUI Typography variant it uses under the hood.
 * This determines what DOM element the text is rendered as (i.e. <h1> v.s. <p>)
 *
 * Note: Disco 2.0 typography doesn't  follow the Material Design typography system
 * In general:
 * `body-lg` => body1
 * `body-base`, `body-md` => body2
 * `body-xs`, `body-sm` => caption
 * `*-uppercase` => overline
 */
const VARIANT_TO_TYPOGRAPHY: Record<TextVariantWithModifiers, MuiTypographyVariant> = {
  "heading-xxl": "h1",
  "heading-xxl-500": "h1",
  "heading-xxl-600": "h1",
  "heading-xxl-700": "h1",
  "heading-xl": "h2",
  "heading-xl-500": "h2",
  "heading-xl-600": "h2",
  "heading-xl-700": "h2",
  "heading-lg": "h3",
  "heading-lg-500": "h3",
  "heading-lg-600": "h3",
  "heading-lg-700": "h3",
  "heading-md": "h4",
  "heading-md-500": "h4",
  "heading-md-600": "h4",
  "heading-md-700": "h4",
  "heading-sm": "h5",
  "heading-sm-500": "h5",
  "heading-sm-600": "h5",
  "heading-sm-700": "h5",
  "heading-xs": "h6",
  "heading-xs-500": "h6",
  "heading-xs-600": "h6",
  "heading-xs-700": "h6",
  "body-lg": "body1",
  "body-lg-500": "body1",
  "body-lg-600": "body1",
  "body-lg-700": "body1",
  "body-lg-uppercase": "overline",
  "body-lg-500-uppercase": "overline",
  "body-lg-600-uppercase": "overline",
  "body-lg-700-uppercase": "overline",
  "body-md": "body1",
  "body-md-500": "body1",
  "body-md-600": "body1",
  "body-md-700": "body1",
  "body-md-uppercase": "overline",
  "body-md-500-uppercase": "overline",
  "body-md-600-uppercase": "overline",
  "body-md-700-uppercase": "overline",
  "body-sm": "body2",
  "body-sm-500": "body2",
  "body-sm-600": "body2",
  "body-sm-700": "body2",
  "body-sm-uppercase": "overline",
  "body-sm-500-uppercase": "overline",
  "body-sm-600-uppercase": "overline",
  "body-sm-700-uppercase": "overline",
  "body-xs": "caption",
  "body-xs-500": "caption",
  "body-xs-600": "caption",
  "body-xs-700": "caption",
  "body-xs-uppercase": "overline",
  "body-xs-500-uppercase": "overline",
  "body-xs-600-uppercase": "overline",
  "body-xs-700-uppercase": "overline",
}

type PaletteTextTypeKind = keyof PaletteTextType
type GroovyPaletteKind = keyof Palette["groovy"]
type GroovyPaletteColorKind = "primary" | "warning" | "error" | "success"
type GroovyColorKey = keyof ColorPaletteStyles
type PaletteColorKind = "main" | "light" | "dark"
export type GroovyTextColorKind =
  | `groovy.${GroovyPaletteKind}.${GroovyColorKey}`
  | `${GroovyPaletteColorKind}.${PaletteColorKind}`
  | `text.${PaletteTextTypeKind}`
  | "common.white"
  | "common.black"
  | "primary.contrastText"

interface StyleProps {
  fontStyle: "normal" | "italic"
  textDecoration?: string
  color?: GroovyTextColorKind
  setColor?: string
  lineHeight?: number
}

const useStyles = makeUseStyles((theme) => ({
  root: ({ setColor, color, fontStyle, lineHeight, textDecoration }: StyleProps) => {
    // Try to get the colour if a path is provided, fallback to theme.palette.text.primary if not found
    const colorValue = color
      ? get(theme.palette, color, theme.palette.text.primary)
      : null
    return {
      fontStyle: `${fontStyle} !important`,
      color: setColor || colorValue || theme.palette.text.primary,
      ...styleIf(lineHeight, {
        lineHeight: `${lineHeight}px !important`,
      }),
      ...styleIf(textDecoration, {
        textDecoration,
      }),
    }
  },
}))

const useSpacingStyles = makeSpacingStyles()
const useTypographyStyles = makeTypographyStyles()

type DiscoTextSkeletonProps = Omit<DiscoTextProps, "color"> &
  Omit<SkeletonProps, "variant">

export const DiscoTextSkeleton: React.FC<DiscoTextSkeletonProps> = ({
  variant = "body-md",
  fontStyle = "normal",
  margin = 0,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  padding = 0,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  className,
  ...rest
}) => {
  const classes = useStyles({
    fontStyle,
  })

  // Pack spacing props into a single object
  const spacing = {
    margin,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    padding,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
  }
  const typographyStyles = useTypographyStyles()
  const spacingStyles = useSpacingStyles(spacing)

  return (
    <Skeleton
      className={classNames(
        classes.root,
        className,
        spacingStyles.root,
        typographyStyles[variant]
      )}
      {...rest}
    />
  )
}
