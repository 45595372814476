import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoRadio, DiscoRadioProps } from "@disco-ui"

type DiscoRadioWithImageProps = DiscoRadioProps

function DiscoRadioWithImage(props: DiscoRadioWithImageProps) {
  const classes = useStyles({ checked: Boolean(props.checked) })
  return (
    <DiscoRadio
      {...props}
      controlClasses={{
        root: classes.radioButtonContainer,
      }}
      classes={{
        root: classes.radioButton,
        label: classes.label,
        sublabel: classes.image,
      }}
    />
  )
}

type StyleProps = {
  checked: boolean
}

const useStyles = makeUseStyles((theme) => ({
  radioButton: {
    padding: 0,
  },
  radioButtonContainer: ({ checked }: StyleProps) => ({
    border: checked
      ? `1px solid ${theme.palette.primary.main}`
      : `1px solid ${theme.palette.constants.stroke}`,
    borderRadius: theme.measure.borderRadius.large,
    padding: theme.spacing(2.5),

    // This is needed override default MUI styles
    "&:last-child": {
      margin: 0,
    },
    "&:not(:last-child)": {
      margin: 0,
    },
  }),
  label: {
    color: theme.palette.groovy.neutral[700],
    marginTop: "-2px",
    marginLeft: theme.spacing(1),
  },
  image: {
    overflow: "hidden", // Needed so the svg doesn't overflow the borderRadius
    borderRadius: theme.measure.borderRadius.default,
    marginTop: theme.spacing(-0.75),
    marginLeft: theme.spacing(-2.5),
    display: "flex",
    alignItems: "center",
  },
}))

export default DiscoRadioWithImage
