import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoInput, { DiscoInputProps } from "@disco-ui/input/DiscoInput"
import classNames from "classnames"
import React from "react"

function DiscoTitleInput({ className, ...props }: Omit<DiscoInputProps, "ref">) {
  const ref = React.createRef<HTMLInputElement | HTMLTextAreaElement>()
  const classes = useStyles()
  return (
    <DiscoInput
      ref={ref}
      className={classNames(classes.titleInput, className)}
      {...props}
    />
  )
}
const useStyles = makeUseStyles((theme) => ({
  titleInput: {
    backgroundColor: "transparent",
    padding: theme.spacing(1, 1.5, 1, 2),
    ...theme.typography["body-lg"],
    ...theme.typography.modifiers.fontWeight[600],
  },
}))
export default DiscoTitleInput
