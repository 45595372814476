import {
  DiscoIcon,
  DiscoIconButton,
  DiscoIconKinds,
  DiscoLinkProps,
  DiscoTooltip,
} from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"

interface Props extends TestIDProps {
  icon: DiscoIconKinds
  tooltip: string
  onClick?: React.MouseEventHandler
  to?: DiscoLinkProps["to"]
}

function DiscoToolbarButton({ onClick, icon, tooltip, to, testid }: Props) {
  return (
    <DiscoTooltip content={tooltip}>
      {/* needed for tooltip */}
      <div>
        <DiscoIconButton onClick={onClick} to={to} testid={testid} width={48} height={48}>
          <DiscoIcon icon={icon} />
        </DiscoIconButton>
      </div>
    </DiscoTooltip>
  )
}

export default DiscoToolbarButton
